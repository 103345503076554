const banner = [
    { "pageId": 958, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1d8qukHSvDi.jpg" },
    { "pageId": 956, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1d8rcylq5d6.jpg" },
    { "pageId": 961, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1d9RftN7f9u.jpg" },
    { "pageId": 953, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1dbxhzM9qLu.jpg" },
    { "pageId": 957, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1dbxo3cD9WE.jpg" },
    { "pageId": 954, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1dbxGWpipEI.jpg" },
    { "pageId": 955, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1dbxIrc8dOg.jpg" },
    { "pageId": 962, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1dlGaXr7ZPq.jpg" },
    { "pageId": 965, "imgUrl": "https://mini.wu.szjlxx.cn/pages/article/banner/1eeeRFAf5Ju.jpg" }
]
function getImgUrl(pageId) {
    const item = banner.find(item => item.pageId == pageId);
    return item ? item.imgUrl : null;
}
export {
    getImgUrl
}