<!-- docs:https://github.surmon.me/vue-awesome-swiper -->
<template>
    <div style="margin: 5px;">
        <swiper :slides-per-view="3" :space-between="30" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="item in props.recommend" :key="item.articleId">
                <div style="width: 140px;" @click="jumpTo(item.articleId)">
                    <div class="card" style="width: 130px;background: white;border-radius:10px;">
                        <img style=" object-fit: cover;margin-top: 5px; width: 120px;height: 120px;border-radius:10px" alt=""
                        :src="item.coverPic" />
                    <div style="width: 100%;">
                        <div><span style="font-weight: bolder;">{{ item.title }}</span></div>
                        <div><span style="color: gray;font-size: 12px;">{{item.subTitle}}</span></div>
                    </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>

    </div>
</template>
<style scoped>
@media screen and (max-width: 420px) {
    .card{
        width:120px !important
    }
    .card img{
        width: 110px !important;
    }
}


</style>
<script setup>
import {defineProps} from 'vue'
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
const onSwiper = (swiper) => {
    console.log(swiper);
};
const onSlideChange = () => {
    console.log('slide change');
};
const props = defineProps(['recommend'])
const jumpTo = (articleId) => {
  window.location.href = "https://mini.wu.szjlxx.cn/pages/article/" + articleId + ".html";
}
</script>
  