<template>
  <div class="hello">
    <div class="context">
      <div class="banner">
        <img v-if="banner" :src="banner" alt="">
      </div>
      <div class="recommend">
        <div class="title">
          <img src="../assets/钻石.png" alt="">
          <span>热门推荐</span>
        </div>
        <div>
          <RecommendArticles :recommend="recomm"></RecommendArticles>
        </div>
      </div>
      <div class="articles">
        <!-- <div class="tag" v-for="item in article"  :key="item.menuId" :class="{ active: activeTag === '必购好物' }" @click="activeTag = '必购好物'"> <span>{{ item.menuName }}</span></div> -->
        <div class="tag" v-for="(item, index) in article" :key="item.menuId"
          :class="{ active: activeTag === item.menuName }" @click="refresh(item, index)">
          <span>{{ item.menuName }}</span>
        </div>
      </div>
      <!-- 这里是物品列表   -->
      <div style="margin: 0px 0px;" v-if="banner" >
        <div v-for="item in aList" :key="item.articleId" @click="jumpTo(item.articleId)"
          style="height: 120px;background: white;border-radius:10px;margin: 10px; display: flex;justify-items: center;align-items:center ;">
          <img :src="item.coverPic"
            style="margin-left: 10px;border-radius:10px;width: 100px;height: 100px;object-fit: cover" alt="">
          <div style="height: 120px;text-align: left;">
            <div class="title"
              style="font-weight: bold;font-size: 16px;margin-left: 10px;margin-top: 10px;margin-bottom: 10px;">
              {{ item.title }}
            </div>
            <div class="des" style="color: gray;margin-left: 10px;">{{ item.subTitle }}</div>
          </div>
        </div>
      </div>
      <div style="margin: 0 10px;" v-if="!banner">
        <a-row type="flex" justify="space-between" :gutter="[8,8]">
          <a-col :span="12"  v-for="item in aList" :key="item.articleId" @click="jumpTo(item.articleId)">
            <div style="background: white;border-radius:10px">
              <img :src="item.coverPic"
            style="border-radius:10px 10px 0px 0px;height: 200px;object-fit: cover" alt="">
            <div style="height: 60px;font-size: 14px;font-weight: bold;">{{ item.title }}</div>
            </div>
          </a-col>
         
        </a-row>
      </div>

    </div>
  </div>
</template>
  
  
  
<script setup>
import { onMounted, ref } from 'vue'
import { getImgUrl } from '@/api/banner'

import axios from 'axios'
import RecommendArticles from '../components/RecommendArticles.vue';

//导入axios
// 选中变色
const activeTag = ref('热门推荐')
// 挑战
const jumpTo = (articleId) => {
  window.location.href = "https://mini.wu.szjlxx.cn/pages/article/" + articleId + ".html";
}
// 获取文章列表
const recomm = ref({})
const article = ref({})
const aList = ref() //景区列表
var t = window.location.href.split('/').slice(-1)[0]
const banner = ref(getImgUrl(t.split(".")[0]))
onMounted(() => {
  axios.get("./api/" + t).then(res => {
    let mList = res.data.body.menuList
    if (mList.length > 1 && mList[0].menuName == "热门推荐") {
      // 有热门推荐就更新热门推荐菜单 
      recomm.value = mList[0].articleList
      article.value = mList.slice(1)
      activeTag.value = mList[1].menuName

    }
    if (mList.length == 1) {
      aList.value = mList[0].articleList
      console.log(aList.value)
    } else {
      aList.value = article.value[0].articleList
    }
    // console.log(aList.value)
  })
})

// 刷新列表

const refresh = (item, index) => {
  activeTag.value = item.menuName
  aList.value = article.value[index].articleList
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.articles {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* 将子元素左对齐 */
  align-items: center;
  /* 垂直居中 */
}

.tag {
  line-height: 30px;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid rgb(212, 212, 212);
  /* color: rgb(75, 75, 75); */
  border-radius: 5px;


}

.tag.active {
  background-color: rgb(233, 100, 100);
  ;
  color: white !important;
  border: none
}

.tag span {
  font-size: 16px;
  /* background: rgb(233, 100, 100); */
  line-height: 25px;
  padding: 6px;
  border-radius: 5px
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 200px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.recommend .title {
  display: flex;
  align-items: center;
  /* 垂直居中 */
  line-height: 40px;
  padding-left: 20px;
  margin-top: 10px;
}

.recommend .title span {
  margin: 0 5px;
}

.recommend .title img {
  width: 25px;
}

.recommend .title span {
  font-size: 16px;
  font-weight: bolder;
}

.banner img {
  overflow: hidden;
  object-fit: cover;
  height: 210px;

}

.context img {
  width: 100%;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
  