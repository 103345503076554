<template>
    <div class="video-container">
        <!-- <img src="../assets/home.png" style="position: absolute;left: 10px;top: 10px;z-index: 999;width: 40px;"  alt=""> -->
        <div class="note" style="position: absolute;bottom: 55px;left:20px;font-size: large;font-weight: 500;color: white;z-index: 999;"> {{video.title}}
        </div>
        <video-player class="video-player vjs-big-play-centered"
            :src="video.videoUrl" :poster="video.vaCoverPic"
            :height="videoH" autoplay loop playsinline controls :volume="0.6" :playback-rates="[0.7, 1.0, 1.5, 2.0]"/>
    </div>
</template>
  
   
  
<script setup>

import {  ref ,onMounted} from 'vue'
import { VideoPlayer } from '@videojs-player/vue'
import {findArticleById} from '@/api/video'
import 'video.js/dist/video-js.css'
import axios from 'axios'
import { useRoute } from 'vue-router';
const video=ref({})
// 

onMounted(()=>{
    const router = useRoute();
    const id = router.params.id;
    axios.get("../api/965.json").then(res=>{
        video.value= findArticleById(res.data,id)
        console.log(video.value)
    })
})

const videoH = ref(document.body.clientHeight)
// const player = shallowRef()

</script>

<style scoped>
.video-container {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    /* height: calc(100vh - 30px); */
}

.video-player {
    width: 100%;
    max-height: 100vh;

}
</style>