import { createRouter, createWebHistory } from 'vue-router';
import ArticlesBox from '@/views/ArticlesBox';
import ShortVideo from '@/views/ShortVideo';


const routes = [
  {
    path: '/:id.json',
    name: 'ArticlesBox',
    component: ArticlesBox,
  },
  {
    path: '/sv/:id',
    name: 'ShortVideo',
    component: ShortVideo,
  },
  {
    path:'/TourRoutes',
    name:'TourRoutes',
    component:() => import('@/views/TourRoutes'),
  },
  {
    path: '/TravelDetail/:id',
    name: 'TravelDetail',
    component: () => import('@/views/TravelDetail'),
  }

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;