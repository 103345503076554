function findArticleById(json, articleId) {
  const menuList = json.body.menuList;
  for (let i = 0; i < menuList.length; i++) {
    const articleList = menuList[i].articleList;
    for (let j = 0; j < articleList.length; j++) {
      if (articleList[j].articleId == articleId) {
        console.log(articleList[j])
        return articleList[j];
      }
    }
  }
  return null; // 没有找到则返回null
}
export {
  findArticleById
}